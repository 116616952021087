// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-page {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .questions-section {
    flex: 3 1;
    margin-right: 20px;
  }
  
  .question-container {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .progress-tracker {
    flex: 1 1;
    max-width: 200px;
    background-color: #fef9e7;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 20px; /* Keeps it fixed as you scroll */
  }
  
  .progress-tracker h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .progress-tracker ul {
    list-style-type: none;
    padding: 0;
  }
  
  .progress-tracker li {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    font-weight: bold;
  }
  
  .correct {
    color: green;
  }
  
  .incorrect {
    color: red;
  }
  
  .question-container button {
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .question-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Question.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;EACf;;EAEA;IACE,SAAO;IACP,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,sCAAsC;IACtC,gBAAgB;IAChB,SAAS,EAAE,iCAAiC;EAC9C;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB","sourcesContent":[".question-page {\n    display: flex;\n    justify-content: space-between;\n    padding: 20px;\n  }\n  \n  .questions-section {\n    flex: 3;\n    margin-right: 20px;\n  }\n  \n  .question-container {\n    background-color: #f9f9f9;\n    padding: 15px;\n    margin-bottom: 15px;\n    border-radius: 8px;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n  }\n  \n  .progress-tracker {\n    flex: 1;\n    max-width: 200px;\n    background-color: #fef9e7;\n    padding: 15px;\n    border-radius: 8px;\n    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);\n    position: sticky;\n    top: 20px; /* Keeps it fixed as you scroll */\n  }\n  \n  .progress-tracker h3 {\n    font-size: 1.2rem;\n    margin-bottom: 10px;\n    text-align: center;\n  }\n  \n  .progress-tracker ul {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .progress-tracker li {\n    display: flex;\n    justify-content: space-between;\n    margin: 5px 0;\n    font-weight: bold;\n  }\n  \n  .correct {\n    color: green;\n  }\n  \n  .incorrect {\n    color: red;\n  }\n  \n  .question-container button {\n    margin-top: 10px;\n    margin-right: 10px;\n  }\n  \n  .question-container button:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
