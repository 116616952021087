import React from 'react';
import { auth } from '../../firebase';
import './Checkout.css';
import SixMonth from '../../images/6Month.png';
import NineMonth from '../../images/9Month.png';
import TwelveMonth from '../../images/12Month.png';

const Checkout = () => {
  const paymentLinks = {
    '6-month': 'https://buy.stripe.com/test_7sI4jf3iS8y62f6dQS',
    '9-month': 'https://buy.stripe.com/test_28odTPdXw29IaLCbIJ',
    '12-month': 'https://buy.stripe.com/test_3cs7vr6v415E3ja000',
  };

  const handleCheckout = async (packageType) => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      alert("You must be logged in to purchase.");
      return;
    }
  
    const response = await fetch('http://localhost:5001/your-firebase-project/us-central1/createCheckoutSession', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ packageType, userId }),
    });
  
    const { id } = await response.json();
    window.location.href = `https://checkout.stripe.com/pay/${id}`;
  };
  

  return (
    <div className="checkout-container">
      <div className="title">
        <h1>UKMLA AKT Question Banks</h1>
      </div>
      <h2>Select a Package</h2>
      <div className="packages">
        <div className="package-card">
          <h3>QBank 6 Months Access</h3>
          <img src={SixMonth} alt="6 Month Access" className="Month-image" />
          <p className="price">£35</p>
          <ul className="features">
            <li>Full QBank Access</li>
          </ul>
          <button className="btn" onClick={() => handleCheckout('6-month')}>
            Buy
          </button>
        </div>
        <div className="package-card">
          <h3>QBank 9 Months Access</h3>
          <img src={NineMonth} alt="9 Month Access" className="Month-image" />
          <p className="price">£40</p>
          <ul className="features">
            <li>Full QBank Access</li>
            <li>1 Self-Assessment*</li>
          </ul>
          <button className="btn" onClick={() => handleCheckout('9-month')}>
            Buy
          </button>
        </div>
        <div className="package-card">
          <h3>QBank 12 Months Access</h3>
          <img src={TwelveMonth} alt="12 Month Access" className="Month-image" />
          <p className="price">£45</p>
          <ul className="features">
            <li>Full QBank Access</li>
            <li>2 Self-Assessments*</li>
            <li>AI Study Assistant</li>
            <li>One-time Reset Option</li>
          </ul>
          <button className="btn" onClick={() => handleCheckout('12-month')}>
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
