// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-container {
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.home-page {
  position: relative;
}

.dashboard-container {
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.how-it-works-container {
  position: relative;
  margin-top: 0px;
  width: 100%;
}

.how-it-works-title {
  position: sticky;
  top: 70px;
  z-index: 5;
  width: 100%;
  font-size: 20px;
  background-color: #e6e8f8;
  padding: 0;
  text-align: center;
  color: black;
  transition: transform 0.5s ease;
}

.how-it-works-section {
  padding: 50px 0;
}

.section {
  margin: 100px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pages/HomePage.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,eAAe;EACf,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,WAAW;EACX,eAAe;EACf,yBAAyB;EACzB,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".home-page-container {\n  max-width: 1500px;\n  margin: 0 auto;\n  padding: 0 20px;\n  box-sizing: border-box;\n  overflow-x: hidden;\n}\n\n.home-page {\n  position: relative;\n}\n\n.dashboard-container {\n  position: relative;\n  z-index: 1;\n  transition: transform 0.5s ease, opacity 0.5s ease;\n}\n\n.how-it-works-container {\n  position: relative;\n  margin-top: 0px;\n  width: 100%;\n}\n\n.how-it-works-title {\n  position: sticky;\n  top: 70px;\n  z-index: 5;\n  width: 100%;\n  font-size: 20px;\n  background-color: #e6e8f8;\n  padding: 0;\n  text-align: center;\n  color: black;\n  transition: transform 0.5s ease;\n}\n\n.how-it-works-section {\n  padding: 50px 0;\n}\n\n.section {\n  margin: 100px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
