import React from 'react';
import './Work.css';
import WorkImage from '../../images/phone.png';
import { useNavigate } from 'react-router-dom';

const Work = () => {
  const navigate = useNavigate(); // useNavigate hook

  // Function to handle navigation on link clicks
  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="create-container12">
      <div className="work-section12">
        <img src={WorkImage} alt="UKMLA AKT Preparation" className="work-image" />
      </div>
      <div className="text-section12">
        <h1>
          Your UKMLA AKT Success Starts Here
        </h1>
        <p>
          Access high-yield practice questions, expertly curated study guides, and interactive tools designed to boost your confidence. <br/>
          <br/>Join thousands of students who trust our quality resources to excel on the UKMLA AKT.
        </p>
        <div className="container12">
          <button className="get-started-btn12" onClick={() => handleClick('/signup')}>
            Sign Up
          </button>
        </div>
      </div>
    </div>
  );
};

export default Work;