// HeroSection.js
import React, { useEffect, useState } from 'react';
import './Hero2.css';

const HeroSection = ({ onScrollToProjectOptimization, onScrollToValueProposition }) => {
  const [startAnimation, setStartAnimation] = useState(false);

  // The hero title text
  const titleText = 'Specialist Vetted Realistic MLA AKT Questions';
  // Split into individual words
  const words = titleText.split(' ');

  useEffect(() => {
    const timer = setTimeout(() => {
      setStartAnimation(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  // Build animated words
  const animatedWords = words.map((word, idx) => {
    const lowerCase = word.toLowerCase();
    const shouldUnderline = (lowerCase === 'project' || lowerCase === 'moccet');
    
    const randomDelay = (Math.random() * 0.8).toFixed(2) + 's';

    return (
      <React.Fragment key={idx}>
        <span
          className={`animated-word53 from-bottom ${startAnimation ? 'active' : ''}`}
          style={{ transitionDelay: randomDelay }}
        >
          {shouldUnderline ? <u>{word}</u> : word}
        </span>
        {idx < words.length - 1 && ' '}
      </React.Fragment>
    );
  });

  return (
    <div className="hero-section53">
      <div className="hero-content53">
        <h1 className="hero-title53">
          {animatedWords}
        </h1>
        
        <p className={`hero-subtitle53 ${startAnimation ? 'subtitle-animate53' : ''}`}>
        Over 5000 comprehensive, specialist vetted, exam style <strong>MLA AKT </strong> Questions
        </p>

        <div className={`cards-container53 ${startAnimation ? 'cards-animate53' : ''}`}>
          <div className="info-card53">
            <h2>MasterMLA AI</h2>
            <h3>Prepare with our specialist AI</h3>
            <p>
            MasterMLA AI has been specialist trained to analyse your answering trends to guide your study focus.
            </p>
            {/* Scroll to ProjectOptimization on click */}
            <button 
              className="card-button53" 
              onClick={onScrollToProjectOptimization}
            >
              Learn More
            </button>
          </div>

          <div className="info-card53">
            <h2>Question Bank</h2>
            <h3>Learn with MasterMLA</h3>
            <p>
            Our specialist study support guide gets you on track to achieve your exam goals.
            </p>
            {/* 4) Use the onScrollToValueProposition prop on click */}
            <button 
              className="card-button53 card-button-outline53"
              onClick={onScrollToValueProposition}
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
