import React from 'react';
import './Create.css';
import CreateImage from '../../images/computer.png';
import { useNavigate } from 'react-router-dom';

const Create = () => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="create-container">
      <div className="text-section">
        <h1>
          Accelerate Your UKMLA Mastery
        </h1>
        <ul className="feature-list">
          <li>✓ Personalized Study Tools</li>
          <li>✓ Expert-Curated Question Bank</li>
          <li>✓ Real-Time Progress Tracking</li>
          <li>✓ Proven Exam Strategies</li>
        </ul>
        <div className="container2">
          <button className="get-started-btn" onClick={() => handleClick('/signup')}>
            Get Started
          </button>
          <p className="account" onClick={() => handleClick('/signin')}>
            Already have an account? Sign in
          </p>
        </div>
      </div>
      <div className="image-section">
        <img src={CreateImage} alt="UKMLA Tools" className="create-image" />
      </div>
    </div>
  );
};

export default Create;
