import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hero2 from '../HomePage/Hero2';
import Footer from '../Footer';
import './HomePage.css';
import JoinClub from '../HomePage/JoinClub';
import Create from '../HomePage/Create';
import Work from '../HomePage/Work';

const HomePage = () => {

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  


  return (
    <div className="home-page-container">
      {/* Home Page Content */}
        <div className="home-page">
          <div
            className="dashboard-container"
            style={{
              transform: `translateY(${scrollPosition / 2}px) scale(${1 - scrollPosition / 1000})`,
              opacity: `${1 - scrollPosition / 500}`,
            }}>
            <Hero2 />
          </div>
          <div className="about-us-container">
            <Create/>
          </div>
          <div className="about-us-container">
            <Work/>
          </div>
          <div className="about-us-container">
            <JoinClub/>
          </div>
          <div className="section" data-aos="fade-up">
          </div>
          <Footer />
        </div>
    </div>
  );
};

export default HomePage;
